<template>
    <v-app>
        <v-container class="fadein" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="6">
                    <div class="success-animation">
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                    </div>
                    <!-- <v-img src="https://www.jems-group.com/wp-content/uploads/2021/03/logo-blueway-horizontal.png" /> -->
                    <v-alert type="success">
                       Un SMS vous a été envoyé. Cliquez sur le lien afin de finaliser votre demande et obtenir votre réponse immédiate.
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>


<script>
import xhr from "../plugins/axios";
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyBRdPtG4HaY4LMh9bYSUnMnKsXYvY9HAAY",
    authDomain: "authentification-fdnauc.firebaseapp.com",
    databaseURL: "https://authentification-fdnauc.firebaseio.com",
    projectId: "authentification-fdnauc",
    storageBucket: "authentification-fdnauc.appspot.com",
    messagingSenderId: "126825964385",
    appId: "1:126825964385:web:ee5358af3e22157852db20",
    measurementId: "G-47VM6GFGFT"
};

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { OAuthProvider } from "firebase/auth";

const firebase = initializeApp(firebaseConfig);

export default {
    name: "Login",
    data() {
        return {
            snackbar: false,
            loading: false,
            valid: true,
            email: "",
            password: "",
            nameRules: [
                (v) => !!v || "Mot de passe obligatoire",
                (v) =>
                    (v && v.length >= 8) ||
                    "Mot de passe doit faire au moins 8 caractères",
            ],
            emailRules: [
                (v) => !!v || "E-mail obligatoire",
                (v) => /.+@.+\..+/.test(v) || "E-mail doit être valide",
            ],
        };
    },
    created() {
        window.scrollTo(0, 0);

    },
    methods: {
        liAuth() {
            IN.User.authorize(() => {
                this.back(data);
            });
        },
        back(data) {
            IN.API.Profile("me").fields(["id", "email-address"])
                .result((result) => {
                    this.setLoginBadge(result.values[0]);
                })
            console.log("data", data);
        },
        setLoginBadge(profile) {
            console.log("profile", profile);
        },
        OauthApple() {
            const auth = getAuth();
            const provider = new OAuthProvider('apple.com');

            provider.addScope('email');
            provider.addScope('name');
            provider.setCustomParameters({
                // Localize the Apple authentication screen in French.
                locale: 'fr'
            });

            signInWithPopup(auth, provider)
                .then((result) => {
                    // The signed-in user info.
                    const user = result.user;

                    // Apple credential
                    const credential = OAuthProvider.credentialFromResult(result);
                    const accessToken = credential.accessToken;
                    const idToken = credential.idToken;


                    console.log(result, "result")
                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                })
                .catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The credential that was used.
                    const credential = OAuthProvider.credentialFromError(error);

                    console.log(error, "error")
                    // ...
                });

        },
        OauthLn() {
            const displayProfileData = (data) => {
                console.log("data", data);
            }
            const onError = (error) => {
                console.log(error);
            }
            const getProfileData = () => {
                IN.API.Profile("me").fields("id", "first-name", "last-name", "headline", "location", "picture-url", "public-profile-url", "email-address").result(displayProfileData).error(onError);
            }

            IN.Event.on(IN, "auth", getProfileData);
            console.log("ici");
        },
        Oauth() {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();

            signInWithPopup(auth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;

                    localStorage.setItem("id", user.uid);
                    localStorage.setItem("email", user.email);
                    localStorage.setItem("url", user.photoURL);
                    localStorage.setItem("name", user.displayName);
                    localStorage.setItem("user", JSON.stringify(user));

                    window.location = "/";

                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);

                    console.log('error', error)
                    // ...
                });
        },
        login() {
            this.loading = true;
            xhr
                .post("/login", { email: this.email, password: this.password })
                .then((res) => {
                    if (!res.data || res.data === null || res.data.success === false) {
                        this.snackbar = true;
                        this.loading = false;
                    } else if (res.data.success === true) {
                        this.loading = false;

                        localStorage.setItem("id", res.data._id);
                        localStorage.setItem("email", res.data.email);
                        localStorage.setItem("user", JSON.stringify(res.data));

                        console.log(this.admin)

                        window.location = "/";
                    }
                });
        },
    },
};
</script>

<style>
.success-animation {
    margin: 50px auto;
}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;

    margin: 0 auto;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}</style>